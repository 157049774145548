{
    class aflVaultItemDetailsController {

        static get $inject() {
            return ['VaultItemPropertiesConstant'];
        }
        constructor(VaultItemPropertiesConstant) {
            this.VaultItemPropertiesConstant = VaultItemPropertiesConstant;
        }
    }

    angular.module('adminApp')
        .component('aflVaultItemDetails', {
            controller: aflVaultItemDetailsController,
            templateUrl: '/static/javascript/admin/afl-vault/afl-vault-item-details/afl-vault-item-details.html',
            bindings: {
                vaultItem: '='
            }
        });
}
